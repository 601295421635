import store from "store/dist/store.legacy.min";
import { XF } from "./js/XF";

const KEY_CODES = {
  DOWN: 40,
  UP: 38,
  ENTER: 13,
};

const historyLimit = 10;
const storageKey = "searchList";
const selectItemClass = "search-menu-item-click";
const deleteItemClass = "search-menu-item-delete";
const searchContextClass = ".search-context";

const searchInput = $("#search-keywords");
const searchForm = $("#top-search-form");
const searchQuery = $("#search_results_for_query");
const historyContainer = $("#filtered-search-history");
const searchQueryPlaceholder = $(".menu-content--search-query");
const subSearchSelectors = $(searchContextClass);
subSearchSelectors.hide();

let formIsSubmitting = false;

window.addEventListener("orientationchange", () => {
  $menu.close();
});

const getCurrentList = () => {
  let currentList: string[] = store.get(storageKey);
  if (!currentList) {
    currentList = [];
  }
  return currentList;
};

if (searchQuery.length !== 0) {
  searchInput.val(searchQuery.data("query"));
}

searchForm.on("submit", () => {
  formIsSubmitting = true;
  const value = String(searchInput.val());

  if (value === "") {
    return;
  }

  let currentList = getCurrentList();
  const index = currentList.indexOf(value);

  if (index > -1) {
    currentList.splice(index, 1);
  }
  currentList.unshift(value);
  if (currentList.length > historyLimit) {
    currentList = currentList.slice(0, historyLimit);
  }

  store.set(storageKey, currentList);
});

const sanitizeHTML = (str: string) => {
  const t = document.createElement("div");
  t.textContent = str;
  return t.innerHTML.replace('"', "&quot;");
};

const $menu = new XF.MenuClick(searchInput, {});

const updateList = (filteredList: string[], value: string) => {
  historyContainer.html("");

  if (value === "") {
    subSearchSelectors.hide();
    searchQueryPlaceholder.hide();
  } else {
    subSearchSelectors.show();
    searchQueryPlaceholder.show();
  }

  filteredList.forEach((historyItem) => {
    const sanitized = sanitizeHTML(historyItem);
    const index = sanitized.indexOf(value);
    const caption = `<strong>${value}</strong>${sanitized.substring(
      index + value.length
    )}`;
    historyContainer.append(
      `<div style="position: relative;" class="menu-linkRow-block" data-value="${sanitized}" qid="recent-search-menu-item"><a href="javascript:void(0)" class=" menu-linkRow ${selectItemClass}" data-value="${sanitized}" style="display: block; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">${caption}</a><i data-value="${sanitized}" style="position: absolute; top: 0px; right: 0px; cursor: pointer; padding: 10px;" class="fa fa-times ${deleteItemClass}" qid="recent-search-menu-item-delete"></i></div>`
    );
  });

  searchQueryPlaceholder[0].innerHTML = value;
};

let activeItem = -1;

const updateActiveItem = (index: number) => {
  let idx = index;
  const els = historyContainer.find(".menu-linkRow");
  historyContainer.find(".menu-linkRow").removeClass("is-selected");
  if (els.length === 0) {
    return;
  }
  if (index < 0) {
    activeItem = els.length - 1;
    idx = activeItem;
  }
  if (els.length > idx) {
    $(els[idx]).addClass("is-selected");
  } else {
    activeItem = 0;
    $(els[activeItem]).addClass("is-selected");
  }
};

searchInput.on("keydown", (e) => {
  if (formIsSubmitting) {
    return;
  }
  if (e.keyCode === KEY_CODES.DOWN) {
    activeItem++;
    updateActiveItem(activeItem);
  } else if (e.keyCode === KEY_CODES.UP) {
    activeItem--;
    updateActiveItem(activeItem);
  } else if (e.keyCode === KEY_CODES.ENTER) {
    const currentList = getCurrentList();
    if (activeItem > -1 && activeItem < currentList.length) {
      const element = currentList[activeItem];
      searchInput.val(element);
    }
    searchForm.trigger("submit");
  } else {
    activeItem = -1;
  }
});

/**
 * Removes all greater than and less than characters from the incoming string
 */
function removeGtLtCharacters(value: string) {
  let cleanValue = value;
  cleanValue = cleanValue.replace(/[<]+/g, "&lt;");
  cleanValue = cleanValue.replace(/[>]+/g, "&gt;");
  return cleanValue;
}

searchInput.on("input focus", function onSearch() {
  const cleanValue = removeGtLtCharacters($(this).val()?.toString() || "");
  const value = String(cleanValue);
  const currentList = getCurrentList();
  const filteredList = currentList.filter((element) => {
    return element.startsWith(value);
  });

  updateList(filteredList, value);

  if (!$menu.isOpen()) {
    $menu.open();
    searchInput.trigger("focus");
  }

  activeItem = -1;
});

$(document).on("click", `.${selectItemClass}`, function onClickSelect() {
  searchInput.val($(this).text());
  $menu.close();
  searchForm.trigger("submit");
});

$(document).on("click", `.${deleteItemClass}`, function onClickDelete(e) {
  e.preventDefault();
  e.stopPropagation();
  const currentList = getCurrentList();
  const removeValue = String($(this).data("value"));
  const index = currentList.indexOf(removeValue);
  if (index > -1) {
    currentList.splice(index, 1);
    historyContainer
      .find(`.menu-linkRow-block[data-value="${removeValue}"]`)
      .hide();
  }
  store.set(storageKey, currentList);
});

$(document).on("click", searchContextClass, (e) => {
  e.preventDefault();

  const constraints = $(e.currentTarget)
    .find(".search-query")
    .data("constraint");
  $("#search-constraint").val(JSON.stringify(constraints));

  searchForm.trigger("submit");
});

$(document).on("click", ".input-search .fa-search", (e) => {
  e.preventDefault();

  searchForm.trigger("submit");
});

$(document).on("click", ".input-search .fa-times", (e) => {
  e.preventDefault();

  $(".input-search input").val("");
});

$(document).on("click", ".advanced", () => {
  const elAdvanced = $<HTMLAnchorElement>(".advanced")[0];
  const value = $(".input-search input").val();
  if (!value) return;
  elAdvanced.href = `${elAdvanced.href}?q=${value}`;
});
